import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrophy, faCheckCircle, faFileAlt} from '@fortawesome/free-solid-svg-icons'
import {Col, Row} from 'reactstrap';
import moment from 'moment';

import CampaignFlowXs from '../../../assets/images/umwelteuro2025-ablauf-sms-voting-mobil-rgb.svg';
import CampaignFlowLg from '../../../assets/images/umwelteuro2025-ablauf-sms-voting-desktop-rgb.svg';


const campaignFlow = (props) => {

    const shortenDate = (from, until) => {
        if (until) {
            return moment(from).format('DD.MM.') + ' - ' + moment(until).format('DD.MM.');
        }
        return moment(from).format('DD.MM.');
    };

    return (
        <Row>
            <picture>
                <source media="(min-width: 768px)"
                        srcSet={CampaignFlowLg}/>
                <img src={CampaignFlowXs} alt=""
                     style={{width:'100%'}}/>
            </picture>
        </Row>
    )
};

export default campaignFlow;

